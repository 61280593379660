<template>
  <GlobalDialog class="flex flex-col dialog w-2/5 overflow-y-auto">
    <h2
      class="mb-4"
      v-text="localizeText('operations.export.title') + operationData.name"
    />

    <VSelect v-model="operationData.profile" :options="profilesForSelect" search :label="localizeText('operations.export.location')" :placeholder="localizeText('operations.export.placeholder')" />
    
    <ul v-if="exportErrors" class="text-state-error">
      <li v-for="error in exportErrors" class="flex items-center">
        <i
          aria-hidden="true"
          class="material-symbols-outlined align-sub text-3xl my-2 mr-1"
        >
          error
        </i>
        <p>{{ error }}</p>
      </li>
    </ul>
    <div class="flex justify-between gap-x-2 pt-4">
      <AButton
        color="secondary"
        :label="localizeText('general.close')"
        @click="close"
      />
      <AButton
        :label="localizeText('general.export')"
        :disable="operationData.profile === null"
        @click="saveOperation"
      />
    </div>
  </GlobalDialog>
</template>

<script lang="ts" setup>
import { defineComponent, shallowRef, computed, ref } from 'vue'
import GlobalDialog from '../../dialogs/GlobalDialog.vue'
import CustomOperationsApi from '@api/CustomOperationsApi'
import AButton from '../../_abstract/AButton.vue'
import type { SmartStore } from '../../QuestionnaireUtils'
import type { IOperationsStore } from '../OperationsUtilts'
import VSelect from '@component-library/inputs/VSelect.vue'

const props = defineProps<{
  operation: {
    att: string
    cond: string
    uuid: string
  }
  store: SmartStore<IOperationsStore> | undefined
}>()

interface OperationData {
  name: string
  att: string
  profile: string | null
}

const operationData = ref<OperationData>({
  name: props.operation.att,
  att: props.operation.cond,
  profile: null
})

const isOrgAdminOrSupportOrSysAdmin = computed(() => AvvStore.state.is_org_admin_or_support_or_sys_admin)

const exportErrors = ref([])

const profiles = ref(AvvStore.state.profiles || [])
const localizeText = window.localizeText

const profilesForSelect = computed (() => {
  const preDefault = []
  if(isOrgAdminOrSupportOrSysAdmin.value) preDefault.push({label: localizeText('operations.export.all_profiles_in_organisation'), value: ""})
  return preDefault.concat(profiles.value.map(profile => {return {label: profile.name, value: profile.id}}))
})

const emit = defineEmits<{
  close: []
}>()

const close = () => {
  emit('close')
}

const saveOperation = async () => {
  try {
    await CustomOperationsApi.create({
      data: {
        name: operationData.value.name,
        profile_id: operationData.value.profile,
        text: operationData.value.att,
        organisation_id: window.AvvStore.state.organisation_id
      }
    })
    emit('close')
    window.avv_toast({
      type: 'success',
      message: localizeText('operations.export.toast'),
      inverted: true
    })
  } catch (error) {
    const response = JSON.parse(error.request.response)
    exportErrors.value = response.error
  }
}
</script>

<script lang="ts">
export default defineComponent({ name: 'ExportDialog' })
</script>
